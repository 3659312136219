.focus {
  transition: transform 0.2s;
}

.focus:hover {
  transform: scale(1.2);
}

.card-title {
  font-size: 2em;
  font-weight: 600;
}

.image-style {
  width: 3.2em;
}

.font-details {
  font-weight: 520;
}

.font-details-b {
  font-size: 4em;
  font-weight: 520;
  color: pink;
}
