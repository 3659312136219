.accordian-main {
  cursor: pointer;
  display: inline-block;
  -webkit-text-decoration: none;
  text-decoration: none;
  font-size: 15px;
  background-color: #ffc0cb;
  border: none;
  border-radius: 3px;
  padding: 8px;
  color: black;
}

.list-styles {
  list-style-type: circle;
}

.image-style1 {
  width: 2em;
}

.url-button {
  background-color: grey !important;
}

.Timeline {
  background-color: grey !important;
}

/* .fCuWlP.fCuWlP {
  border: 2px solid pink !important;
}
.dWSdEd.dWSdEd {
  background-color: grey !important;
} */

#projectTitle {
  font-size: 8em !important;
}
