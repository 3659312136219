.img-fluid {
  width: 100%;
  height: auto;
  padding: 10 10 10 10;
  margin: 10 10 10 10;
}

#firstleaf {
  width: 300px;
  height: 450px;
}

.email-description {
  font-size: 1.5em;
  text-align: center;
  font-weight: 500;
  margin-bottom: 30px;
  padding-left: 15%;
  padding-right: 15%;
}
