.about {
  background-color: transparent;
  font-size: 1.4em;
  font-weight: 500;
}

.profile {
  height: 400px;
  width: 300px;
}

.my-details {
  background-color: rgba(255, 255, 255, 0.747);
}

.font-details {
  font-size: 3em;
  font-weight: 520;
  color: pink;
}
