.taj {
  color: pink !important;
  font-size: 2em !important;
  margin-left: 5%;
}

.nav-theme {
  background-color: white;
  font-size: 22px;
}

.animate-navbar {
  box-shadow: 1.5px 1.5px 1.5px rgb(170, 169, 169);
  animation: moveDown 0.7s ease-in-out;
}

@keyframes moveDown {
  from {
    transform: translateY(-4rem);
  }
  to {
    transform: translateY(0rem);
  }
}
