hr {
  border: 0;
  height: 1.5px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.75),
    rgba(0, 0, 0, 0)
  );
}

.container-box {
  width: 85%;
  background-color: transparent;
  position: relative;
  z-index: 2;
}

.particles-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
